@import "../../../../Variables.scss";

.auction {
  h2 {
    color: #fff;
  }

  p {
    margin-bottom: 4px;
  }

  h3 {
    color: #00d368;
  }

  .auction-description {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 767px) {
  .mobile-buttons {
    background-color: #ebebeb;
    position: -webkit-sticky;
    position: sticky;
    top: 68px;
    z-index: 300;
  }
}

.auction-title {
  margin: 30px 0;
}

.auction-info {
  color: #fff;
  margin-top: 30px;

  p {
    font-size: 1.2rem;
  }
}

.statusBadge {
  font-size: 22px;
  padding-bottom: 3px;
}

.flip-clock-wrapper {
  .title {
    display: none;
  }
}

.online {
  color: #fff;
  animation: blink 2s linear infinite;
}

.open {
  color: #58ec33;
  font-weight: bold;
}
.offline {
  color: #e72525;
  font-weight: bold;
}

.newBid {
  box-shadow: 0 0 12px 4px #00b258;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.led-green {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #abff00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px,
    #89ff00 0 2px 12px;
}

.led-box {
  height: 30px;
  width: 5%;
  margin: 0 10px;
  display: inline-flex;
}

.led-box p {
  font-size: 12px;
  text-align: center;
  margin: 1em;
}

.led-red {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: rgb(45, 211, 73);
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #abff00 0 -1px 9px,
    rgb(2, 83, 33) 0 2px 12px;
  -webkit-animation: blinkGreen 0.5s infinite;
  -moz-animation: blinkGreen 0.5s infinite;
  -ms-animation: blinkGreen 0.5s infinite;
  -o-animation: blinkGreen 0.5s infinite;
  animation: blinkGreen 0.5s infinite;
}

@-webkit-keyframes blinkGreen {
  from {
    background-color: rgb(45, 211, 73);
  }
  50% {
    background-color: rgb(21, 100, 6);
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #abff00 0 -1px 9px,
      rgb(2, 83, 33) 0 2px 0;
  }
  to {
    background-color: rgb(45, 211, 73);
  }
}
@-moz-keyframes blinkGreen {
  from {
    background-color: rgb(45, 211, 73);
  }
  50% {
    background-color: rgb(21, 100, 6);
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #abff00 0 -1px 9px,
      rgb(2, 83, 33) 0 2px 0;
  }
  to {
    background-color: rgb(45, 211, 73);
  }
}
@-ms-keyframes blinkGreen {
  from {
    background-color: rgb(45, 211, 73);
  }
  50% {
    background-color: rgb(21, 100, 6);
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #abff00 0 -1px 9px,
      rgb(2, 83, 33) 0 2px 0;
  }
  to {
    background-color: rgb(45, 211, 73);
  }
}
@-o-keyframes blinkGreen {
  from {
    background-color: rgb(45, 211, 73);
  }
  50% {
    background-color: rgb(21, 100, 6);
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #abff00 0 -1px 9px,
      rgb(2, 83, 33) 0 2px 0;
  }
  to {
    background-color: rgb(45, 211, 73);
  }
}
@keyframes blinkGreen {
  from {
    background-color: rgb(45, 211, 73);
  }
  50% {
    background-color: rgb(21, 100, 6);
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #abff00 0 -1px 9px,
      rgb(2, 83, 33) 0 2px 0;
  }
  to {
    background-color: rgb(45, 211, 73);
  }
}

.led-yellow {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #ff0;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px,
    #ff0 0 2px 12px;
  -webkit-animation: blinkYellow 1s infinite;
  -moz-animation: blinkYellow 1s infinite;
  -ms-animation: blinkYellow 1s infinite;
  -o-animation: blinkYellow 1s infinite;
  animation: blinkYellow 1s infinite;
}

@-webkit-keyframes blinkYellow {
  from {
    background-color: #ff0;
  }
  50% {
    background-color: #aa0;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px,
      #ff0 0 2px 0;
  }
  to {
    background-color: #ff0;
  }
}
@-moz-keyframes blinkYellow {
  from {
    background-color: #ff0;
  }
  50% {
    background-color: #aa0;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px,
      #ff0 0 2px 0;
  }
  to {
    background-color: #ff0;
  }
}
@-ms-keyframes blinkYellow {
  from {
    background-color: #ff0;
  }
  50% {
    background-color: #aa0;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px,
      #ff0 0 2px 0;
  }
  to {
    background-color: #ff0;
  }
}
@-o-keyframes blinkYellow {
  from {
    background-color: #ff0;
  }
  50% {
    background-color: #aa0;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px,
      #ff0 0 2px 0;
  }
  to {
    background-color: #ff0;
  }
}
@keyframes blinkYellow {
  from {
    background-color: #ff0;
  }
  50% {
    background-color: #aa0;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px,
      #ff0 0 2px 0;
  }
  to {
    background-color: #ff0;
  }
}

.led-green {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #abff00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px,
    #89ff00 0 2px 12px;
}

.led-blue {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #24e0ff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px,
    #3f8cff 0 2px 14px;
}

@media only screen and (min-width: $screen-sm) {
  .auction-home-desc {
    margin-left: 2rem;
    color: $vmeGreen;
  }
}
@media only screen and (max-width: $screen-sm) {
  .auction-home-desc {
    margin-left: 0;
    color: $vmeGreen;
  }
}

.my-auction-none {
  text-align: center !important;
}

.merc-banner-blue{
  padding:8px;
  background-color: #00adef;
  border: 1px solid #d7d7d7;
  color: white;
}

.merc-banner-white{
  padding:8px;
  background-color: white;
  border: 1px solid #d7d7d7;
  color: #00adef;
}

.merc-banner-grey{
  padding:8px;
  background-color: #666666;
  border: 1px solid #d7d7d7;
  color: white;
}
@import './Variables.scss';

$radius: 4;

@mixin border-radius {
    -webkit-border-radius: ($radius * 1px)!important; 
    -moz-border-radius: ($radius * 1px)!important; 
    border-radius: ($radius * 1px)!important; 
}

@mixin form-container {
    background-color: $vmeLightGrey;
    @include border-radius();
    padding: 20px;
}
@mixin table-border {
    border-collapse: collapse;
    border: 1px solid $vmeLightGrey;
}
@import "../../Variables.scss";

@media only screen and (min-width: $screen-sm) {
  .notification-time-sm {
    display: none !important;
  }

  .clear-all {
    background-color: #ddd;
    padding: 5px 17px;
    border-radius: 4px;
  }
}

@media only screen and (min-width: $screen-lg) {
  .notifications-container {
    &:hover {
      background-color: #ddd;
      cursor: pointer;
    }
  }

  #notificationsContent {
    display: none !important;
  }
}

@media only screen and (max-width: $screen-sm) {
  .notification-time {
    display: none !important;
  }

  .clear-all {
    background-color: $vmeLightGrey;
    padding: 5px 17px;
    border-radius: 4px;
  }
}

.notification-lg {
  overflow-y: auto !important;
  width: 50vw;
}

.notifications-scroll {
  max-height: 75vh;
  overflow-y: scroll;
}

.notifications-container {
  display: block;
  p,
  span {
    display: inline;
    font-size: 12px !important;
  }
  .message {
    font-size: 13px !important;
  }
  .not-times {
    span,
    svg {
      display: inline;
    }
  }
}
.nav-notification {
  color: $vmeRed;
  cursor: pointer;
  font-size: 1.1rem !important;
}

.nav-notification-dot {
  color: $vmeRed;
  font-size: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 3px;
}

.notification-time,
.notification-time-sm {
  color: grey;
  font-size: 0.8rem;
}

.login-logo {
  width: 270px;
  height: 87px;
  margin: 0 20px 20px 20px;
}

.right {
  right: 0;
}

.nav-notification-box {
  position: fixed;
  background: white;
  border: 1px solid black;
}

:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: 0.75rem;
}

.vmBackground {
  background: #00ac53;
  background: linear-gradient(to bottom right, #00ac53, #ffff);
  height: 100vh;
}

.card-signin {
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.card-signin .card-title {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.card-signin .card-body {
  padding: 2rem;
}

.form-signin {
  width: 100%;
}

.clickable {
  cursor: pointer;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input {
  height: auto;
  border-radius: 2rem;
}

.form-label-group > input,
.form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

.btn-google {
  color: white;
  background-color: #ea4335;
}

.hasErrors {
  color: #ea4335;
}

.cta {
  background: #ff7e12;
  color: #ffff;
}

.btn-facebook {
  color: white;
  background-color: #3b5998;
}

.forgotPassword {
  font-size: 10px;
  display: block;
}

/* Fallback for Edge
  -------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
  -------------------------------------------------- */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}

.logout-icon {
  font-size: 1rem;
  color: grey;
}

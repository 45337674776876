@import "../../../Variables.scss";

.pledge {
  .head {
    color: $vmeGreen !important;
    padding-left: 50px;
    font-weight: 600;
  }

}

@import '../../../../Variables.scss';

.card-cont{
    position: relative;
}

.card-body {
    padding: 0.7rem 1.25rem !important;
}

.auction {

     p {
        font-size:1rem;
        margin-bottom: 0.7rem;
    }

    .h5{
        margin-top: 0!important;
    }

    .card-title{
        //margin-bottom: 0!important;
    }
}

.auction-card-description{
    color: #666;
    font-size: 0.9rem;
    margin-top:3px;
    margin-bottom: 0;
}

.card-btn {
    width:80%;

    &--mb {
        width:80%;
        margin-bottom:15px;
    }
}


@media only screen and (max-width: $screen-lg) {

    .card-btn-group {
        display: none;
    }
}

@media only screen and (max-width: $screen-lg) and (min-width: $screen-sm) {

    .small-btns {
        &--left {
            padding-right:15px!important;
        }
 
        &--right {
         padding-left:15px!important;
     }
    }
}

@media only screen and (max-width: $screen-sm) {

    .small-btns {
        &--left {
            margin-bottom: 15px;;
        }
    }

}

@media only screen and (max-width: $screen-md) and (min-width: $screen-sm) {

    .small-title {
        h4, p {
            display: inline;
        }

        p {
            margin-left: 2rem
        }
    }
}

@media only screen and (min-width: $screen-lg) {

    .small-card {
        display: none!important;
    }
}

.card-img{
    height: 100%;
    object-fit: cover;
    max-width: 100vw;
    max-height: 300px;
}

.vme-card {
    box-shadow: 0 7px 18px #d2d2d2;
    margin:2rem;

    &--buynow{
        margin:0;
    }
}

@media only screen and (min-width: $screen-lg){
    .vme-card {
    .card-footer {
        bottom: 0;
        position: absolute;
        width: 100%;
    }

    .card-content{
        margin-bottom:30px;
    }
}
}

@media only screen and (max-width: $screen-md) {

    .vme-card{
        margin: 1.5rem;
        margin-top: 2rem;
    }
}

@media only screen and (max-width: $screen-sm) {

    .vme-card{
        margin: 0;
        margin-top: 2rem;
    }
}

.auction-label {
    background-color: darkgray;
    border: 1px solid grey;
    padding: 4px;
    color: white;
    font-weight: 600;

    position: absolute;
    right: 0;
}  


.card-img {
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;

}

.card-icon-green {
    margin-right: 7px;
    font-size: 0.8rem !important;
    color: #00B258;
} 

.card-icon-orange {
    margin-right: 7px;
    font-size: 0.8rem !important;
    color: #ff7e12;
}

.card-footer {
    padding: 0.25rem 1.25rem!important;
    border-bottom-right-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px)!important;

    &--white{
        background-color: #fff!important;
        padding: 0.5rem 1.25rem 1.25rem 1.25rem!important;
        border-bottom-right-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px)!important;
    }
}

.live {
    color: #008f47;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.preview {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
}

.upcoming {
    color: #383d41;
    background-color: #ff980080;
    border-color: #ff9800b5;
}

.closed {
    color: #fafafa;
    border-color: 1px solid rgba(0, 0, 0, 0.125)!important;
    background-color: rgba(69, 68, 68, 0.54) !important;
}

.card-btns {
    display: flex;
}

.auction-card-btns {
    position: absolute!important;
    top: 20px;
    right: 20px;
    bottom: 20px;
}


@media only screen and (min-width: $screen-md) {

    .buynow-btns {
        width:100%;

        &--left {
            width:100%;
            margin-left: -20px;
        }
        
    }
}

@media only screen and (max-width: $screen-md)  {
    .buynow-btns {
        margin: 12px;

        &--left {
            margin: 12px;
        }
    }
}


@import '../../Variables.scss';

.vmeFooter{
    background: #666;
    color: white;
    background-color: #585756;

    a{
        color: white !important;
    }

    p {
        margin-top:0;
        margin-bottom:0!important;
        font-size: 0.8rem;
        color: #b1b1b0;
    }

    h2 {
        font-size:0.9rem;
        font-weight:600;
        color: #fff;
    }

    .flogo {
        max-width:76%;
        height: 66px;
    }

    .footer-logo{
        width: 85%;
        text-align: left;
        padding: 1px 9px 0px 4px!important;
    }

    .brand, p{

        span {
            color: #699b93;
        }
    }
     a{
         p:hover {
            color: #fff!important;
         }
     }

     .slogan{
        line-height: 16px;
     }
}    

.footer-bottom {
    font-size: 0.8rem;
    color: #fff;
    background-color: grey;

    p {
        font-size: 0.8rem;
    }
}

@media only screen and (max-width: $screen-md) {
    .slogan {
        width: 58%;
        margin:auto;
    }
    
    .brand {
        justify-content: center;
        display: flex;
    }
   
    .footer-logo{
        width: 200px!important;
        padding: 1px 9px 0px 4px!important;
    }

    .finfo {
        text-align: center;
     }

     .flogo {
        height: 96px;
    }
}

@media only screen and (min-width: $screen-md) {
    .finfo {
        display: inline-block;
        text-align: left;
     }
     .finfo-cont {
        text-align: center;
    }

     .flogo {
        max-width:200px!important;
    }

    .brand {
        display: flex;
        justify-content: center;
    }

    .footer-logo {
        padding: 1px 13px 1px 4px!important;
    }

    .flogo {
        img { width: 160px!important;}
    }
}

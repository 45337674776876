@import '../../../../Variables.scss';

.countdown {
    background: rgba(255,255,255, 0.85);
    border-radius: 1rem;
}

  .coming-soon .ctdown {
    font-weight: 700;
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
    padding: 0 1rem 1.2rem 1rem;
  }

  .ctdwn-title {
      line-height: 7px;
      padding-top: 20px;
      font-weight: 600;
  }

  .time-box {
    display: flex;
    flex-direction: column;
    color: black;
    align-items: center;
    justify-content: center;
    width: 115px;
    border-radius: 1rem;
  }
  
  .separator {
    display: flex;
    flex-direction: column;
    color: black;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    font-size: 1.5rem;
  }

  .time-box:first-child {
    margin-left: 20px;
  }  

  .time-box:last-child {
    margin-right: 20px;
  }
  
  .time-box p {
    font-size: 2.5rem!important;
    margin-bottom: -7px;
    color:$vmeGreen;
  }

  .time-box span {
    font-size: 0.8rem!important;
    font-weight: 600;
  }
  

@media only screen and (min-width: $screen-md) {
    .countdown {
        margin: 0 20px;
    }
}

@media only screen and (max-width: $screen-md) {

    .time-box {
        padding-left:8px!important;
        padding-right:8px!important;

        p {
            font-size: 2rem!important;
        }

        span {
            font-size:0.8rem;
        }
    }
}

@media only screen and (max-width: $screen-sm) {
    .auction {
        h2 {
            font-size: 1.5rem;
        }
    }

    .time-box {
        padding-left:8px!important;
        padding-right:8px!important;

        p {
            font-size: 1.3rem!important;
        }

        span {
            font-size:0.8rem;
        }

        .separator {
            font-size: 1rem;
        }
    }
}
  
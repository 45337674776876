@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../src/Variables.scss";
//@import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';

//Overwrite bootstrap defaults here

.flex-wrapper {
  display: flex;
  min-height: calc(100vh - 69px);
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
}

.content {
  padding-bottom: 2rem;
}

$theme-colors: (
  "primary": $vmeOrange,
  "success": $vmeGreen,
  "info": $vmeBlue,
);

// /* set the overriding variables */
$grid-breakpoints: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
) !default;

:root {
  --breakpoint-xxxs: 0;
  --breakpoint-xxs: 320px;
  --breakpoint-xs: 568px;
  --breakpoint-sm: 667px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1440px;
  --breakpoint-xxxl: 1600px;
}

@font-face {
  font-family: Frutiger-Condensed;
  src: url("/src/Fonts/FrutigerLTStd-Cn.otf") format("woff");
}

@font-face {
  font-family: "Fruitger-Light";
  src: url("/src/Fonts/FrutigerLTStd-Light.otf");
}

@font-face {
  font-family: "Fruitger-LightCn";
  src: url("/src/Fonts/FrutigerLTStd-LightCn.woff") format("woff");
}

@font-face {
  font-family: "Frutiger-Roman";
  src: url("/src/Fonts/FrutigerLTStd-Roman.woff") format("woff");
}

@font-face {
  font-family: "Frutiger-Bold";
  src: url("/src/Fonts/FrutigerLTStd-Bold.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "Frutiger-Black";
  src: url("/src/Fonts/FrutigerLTStd-Black.woff") format("woff");
}

@font-face {
  font-family: "Frutiger";
  src: url("../src/Fonts/Frutiger.ttf") format("truetype");
}

//Global classes
@media (max-width: $screen-xxs) {
  .btn {
    //width: 100%!important;
  }
}

.btn {
  font-weight: 500 !important;
}

@media (max-width: $screen-md) {
  .vm-tabs {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.d-grid {
  display: grid !important;
}

.vme-icons {
  color: $vmeGreen;
  margin-right: 7px;
  font-size: 1rem !important;
}

.gradient-grey {
  box-shadow: 3px 3px 10px 5px #ccc;
}

// Tabs - add .three .four etc for number of tabs - add below for more
.vm-tabs {
  @media only screen and (min-width: $screen-lg) {
    //padding: 30px;

    &.three {
      .nav-item {
        width: 33.3%;
      }
    }

    &.four {
      .nav-item {
        width: 25%;
      }
    }

    &.five {
      .nav-item {
        width: 20%;
      }
    }

    &.six {
      .nav-item {
        width: 16.66667%;
      }
    }
  }

  @media only screen and (max-width: $screen-lg) {
    padding: 30px 0;

    .nav-link {
      padding: 0.5rem 1rem;
      color: #000 !important;
    }

    ul {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      flex-wrap: nowrap;
      border-bottom: 0;

      li {
        display: inline;
      }
    }
  }

  .nav-tabs {
    border-bottom: none;
  }

  .nav-item {
    text-align: center;
  }

  .active {
    border: none;
    margin: 1px;
  }

  .nav-link.active {
    background-color: $vmeLightGrey !important;
    color: $vmeGreen;
    font-weight: bold;
    border: 0;
  }

  .nav-link {
    cursor: pointer;
    color: #000 !important;
  }

  // .nav-link.active {
  //   border: 0;
  //   background-color: $vmeLightGrey !important;
  //   color: $vmeGreen !important;
  // }

  .nav-link:hover {
    border: 0;
    margin: 1px;
  }

  .tab-content {
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    background-color: $vmeLightGrey;
    padding: 20px;
    margin: 0 1px 1px 1px;
    min-height: 300px;
  }
}

.light-grey-box {
  background-color: $vmeLightGrey;
  border-radius: 4px;
  padding: 15px !important;
}

.grey-box {
  background-color: #eee;
  border-radius: 4px;
  padding: 15px;

  &--bottom {
    padding-bottom: 50px;
  }
}

.text-bold {
  font-weight: 600;
}

.privacy-text {
  color: #505050;
  font-size: 0.9rem;
  font-style: italic;
}

.form-check {
  padding-left: 0.7rem !important;
}

@media only screen and (max-width: $screen-md) {
  .form-check {
    padding-left: 0 !important;
  }
}

.form-check-label {
  margin-left: 8px;
}

.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}

.form-control:focus {
  box-shadow: none !important;
}

.row-full {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
}

@media only screen and (max-width: $screen-lg) {
  .mob-mb-3 {
    margin-top: 20px;
  }
  .vm-tabs .tab-content {
    padding: 10px;
  }
}

.break-out {
  width: 100vw;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
}

//Extra Bootstrap margins/padding
@include media-breakpoint-down(md) {
  .mb-md-1 {
    margin-bottom: ($spacer * 0.25);
  }
  .mb-md-2 {
    margin-bottom: ($spacer * 0.5);
  }
  .mb-md-3 {
    margin-bottom: ($spacer * 0.75);
  }
  .mb-md-4 {
    margin-bottom: ($spacer * 1);
  }
  .mb-md-5 {
    margin-bottom: ($spacer * 1.25);
  }
}

@import "../node_modules/bootstrap/scss/bootstrap";
@import "../src/Mixins.scss";

//other css below here

body {
  cursor: default;

  h1,
  h3,
  h4 {
    color: $vmeGreen;
  }

  h3 {
    //font-size: 140%;
  }

  h4 {
    font-size: 1.45rem;
    margin-top: 0.5rem;
  }

  a {
    color: $vmeGreen;

    &:hover {
      text-decoration: none;
    }
  }

  .form-container {
    @include form-container;
  }

  footer {
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;

      &.logo {
        max-width: 250px;
      }

      &.awards {
        max-width: 120px;
      }
    }

    ul {
      list-style-type: none;
      padding: 0px;

      li {
        margin: 0 0 10px 0;
      }
    }
  }

  @media (max-width: $screen-xs) {
    footer {
      text-align: center;
    }
  }

  @media only screen and (max-width: $screen-sm) {
    h4 {
      font-size: 1.25rem;
    }
  }
}

@media only screen and (max-width: $screen-sm) {
  .mob-mt-5 {
    margin-top: 10px !important;
  }
}

//icons
.thanks-icon {
  color: $vmeGreen;
  font-size: 4rem;
  margin: 20px;
}

.grey-button {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
  border-radius: "0";
  &:hover {
    background-color: #424242;
    border-color: #424242;
  }
}

.web-content{
  margin-bottom:0!important;

  p{
    span {
      @media only screen and (max-width: $screen-sm) {
        font-size: 15px!important;
      }
    }
  }
}
.cancelled {
  background-color: #ff54543d !important;
}
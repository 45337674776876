.mobile-only {
    .text-image:nth-child(odd) {
        background-color: #f1f1f1;
    }

    .text-image:nth-child(even) {
        background-color: #f6f6f6;
    }

    .text-image .text-image-container .image .reg-badge {
        right: 70px;
        left: 70px;
    }
}

.desktop-only {
    .text-image {
        background-color: #f6f6f6;
    }
}

.text-image {
    padding: 5rem 0;

    @media screen and (min-width: 768px) {
        padding: 6rem 0;
    }

    .row-reverse {
        flex-direction: row-reverse;

        .reverse {
            text-align: right !important;

            @media screen and (min-width: 768px) {
                padding-left: 60px;
            }

            @media screen and (min-width: 922px) {
                padding-left: 80px;
            }
        }

        @media screen and (min-width: 768px) {
            .text {
                text-align: right !important;
            }
        }
    }

    .text-image-container {
        .badge {
            margin: 10px 0px;
            border-radius: 1rem;
        }

        .text {
            text-align: center;

            h2 {
                margin-bottom: 0.8rem;
            }

            @media screen and (min-width: 768px) {
                text-align: left;
            }
        }

        .image {
            margin-top: 1rem;

            @media screen and (min-width: 768px) {
                margin-top: 0;
            }

            img {
                width: 100%;
                max-height: 450px;
                max-width: 400px;
            }

            .image-container {
                position: relative;

                margin-top: 2rem;

                // padding: 4rem 2rem 0 2rem;

                // @media screen and (min-width: 576px) {
                //     padding: 6rem 2rem 2rem;
                // }

                // @media screen and (min-width: 650px) {
                //     padding: 3rem;
                // }

                @media screen and (min-width: 768px) {
                    padding: 0;
                    margin-top: 0;
                }
            }

            .reg-badge {
                position: absolute;
                bottom: 0;
                right: 12px;
                background: linear-gradient(180deg, #f8d038, #f5ca2e);
                color: #000;
                border-radius: 0.25rem;

                @media screen and (min-width: 992px) {
                    right: 42px;
                }

                @media screen and (min-width: 1200px) {
                    right: 85px;
                }
            }


        }
    }
}

.center-text {
    text-align: center !important;
}

@media screen and (max-width: 767px) {
    .text-image-container {
        img {
            width: 90%;
        }
    }
}

@media screen and (min-width: 768px) {
    .text-image-container {
        display: flex;
        align-items: center;

        img {
            width: 295px;
        }
    }
}

@media screen and (min-width: 992px) {
    .intro-container {
        img {
            width: 350px;
        }
    }
}

.no-border-img {
    img {
        box-shadow: none !important;
        max-height: 350px;
        width: auto !important;
    }
}

.image-top {
    display: flex;
    flex-flow: column-reverse;
}

.section-flex {
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 768px) {
        flex-direction: row;
    }

}

.no-image {
    display: flex;
}

.container-flex {
    display: flex;
    align-items: center;
    
    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: center;
    }
}
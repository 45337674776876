@import "../node_modules/bootstrap/scss/mixins/_breakpoints";

$vmeLightGrey: #f6f6f6;
$LightGrey2: #f8f8f8;
$LightGrey1: #f0f0f0;
$vmeGreen: #20a64b;
$vmeOrange: #ff7e12;
$vmeBlue: #1d4289;
$vmeRed: #f43d3d;


$screen-xxs: 320px;
$screen-xs: 375px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xlg: 1200px;

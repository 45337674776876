@import "../../../../../Variables.scss";

@media only screen and (max-width: $screen-sm) {
  .vme-img {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.vm-img-container {
  position: relative;
}

.badge-green {
  color: #fff;
  background-color: $vmeGreen;
}

.badge-orange {
  color: #fff;
  background-color: $vmeOrange;
}

.badge-reg {
  background: linear-gradient(180deg, #f8d038 0, #f5ca2e);
  color: black;
}

.vehicle-title {
  margin-bottom: 1.1rem;
}

.mileage,
.location {
  font-size: 1.2rem;
}

.carousel .slide {
  background: #fff !important;
}

.thumbs {
  padding-left: 0;
}

.thumbs-wrapper {
  margin: 0px 0 0px 0 !important;
  padding-top: 18px;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #b1b0b0;
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #b1b0b0;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.vehicle-container {
  background-color: #f8f7f4;
  padding: 15px 3px;
  margin-bottom: 0.5rem !important;
  border-radius: 4px;
}

.thumb {
  opacity: 0.5;
}

.img-thumbnail {
  border-radius: 5px !important;
  border: none !important;
}

@media screen and (max-width: $screen-sm) {
  .slide.selected .img-thumbnail {
    border-radius: 0px !important;
  }
}

.carousel .thumb {
  padding: 0 !important;
  border: none !important;
  margin-right: 9px;
}

.carousel .thumb:focus {
  border: 0;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  opacity: 1;
  border: none !important;
}

.thumb:hover {
  cursor: pointer;
}

.carousel .thumb:hover:after {
  border-radius: 5px !important;
}

.carousel .thumb:focus {
  border-radius: 5px !important;
  outline: 0 !important;
}

.img-thumbnail {
  padding: 0 !important;
}

.spec-container {
  margin-top: 8px;
  -webkit-columns: 3 200px;
  -moz-columns: 3 200px;
  columns: 3 200px;
  -webkit-column-fill: balance;
  -moz-column-fill: balance;
  column-fill: balance;
  height: 100%;
}

.specs {
  break-inside: avoid-column;
  list-style: none;
  margin: 0 6px 20px 6px;
  border-bottom: 1px solid #ccc;

  p {
    margin-bottom: 2px;
    display: inline-block;
  }

  p.f-val {
    font-weight: bold;
  }

  p:nth-child(2) {
    float: right;
    font-size: 0.9rem;
    line-height: 1.6rem;
    font-weight: bold;
  }
}

@media only screen and (max-width: $screen-lg) {
  .spec-container {
    margin-top: 8px;
    -webkit-columns: 2 200px;
    -moz-columns: 2 200px;
    columns: 2 200px;
    -moz-column-fill: auto;
    column-fill: auto;
  }
}

@media only screen and (max-width: $screen-md) {
  .spec-container {
    margin-top: 8px;
    -webkit-columns: 1 200px;
    -moz-columns: 1 200px;
    columns: 1 200px;
    -moz-column-fill: auto;
    column-fill: auto;
  }

  .specs {
    margin: 0 20px 20px 18px;
  }
}

.img-label {
  &--top {
    display: inline-block;
    padding: 10px;
    width: 100%;
    line-height: 31px;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  &--bottom {
    display: inline-block;
    padding: 5px 7px;
    width: 100%;
    line-height: 31px;
    position: absolute;
    bottom: 102px;
    z-index: 1;
  }
}

.reg {
  background: linear-gradient(to bottom, #f8d038 0%, #f5ca2e 100%);
  padding: 1px 8px;
  font-weight: bold;
  font-size: 1.1rem;
  border-radius: 5px;
  border: 1px solid #000;
  position: relative;
}

.carousel.carousel-slider .control-arrow {
  top: 44%;
  border-radius: 50%;
  height: 30px;
  margin-right: 6px;
  margin-left: 6px;
}

.carousel.carousel-slider .control-arrow:hover {
  background: rgba(110, 110, 110, 0.84);
  border-radius: 50%;
  height: 30px;
  margin-right: 6px;
  margin-left: 6px;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #e0e0e0;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #e0e0e0;
}

.carousel .control-prev.control-arrow:before,
.carousel.carousel-slider .control-prev.control-arrow:before {
  margin: 3px 8px 12px 5px !important;
}

.carousel .control-next.control-arrow:before,
.carousel.carousel-slider .control-next.control-arrow:before {
  margin: 3px 6px 11px 8px !important;
}

@media screen and (max-width: $screen-md) {
  .carousel.carousel-slider .control-arrow {
    margin-right: 3px;
    margin-left: 3px;
  }

  .carousel.carousel-slider .control-arrow:hover {
    margin-right: 3px;
    margin-left: 3px;
  }
}

.thumbs-wrapper {
  min-height: 100px;
}

.thumbs-wrapper .control-next.control-arrow:before {
  margin: 3px 5px 0 4px !important;
}

.thumbs-wrapper .control-prev.control-arrow:before {
  margin: 4px 0 !important;
}

.thumbs-wrapper .control-next.control-arrow,
.thumbs-wrapper .control-prev.control-arrow {
  background: #676b698a;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  margin-right: 2px;
  margin-left: 5px;
}

.thumbs-wrapper .control-next.control-arrow:hover,
.thumbs-wrapper .control-prev.control-arrow:hover {
  background: rgba(110, 110, 110, 0.84);
}

.vehicle-icons p {
  color: #817878;
  margin-bottom: 0;
}

.icon-right {
  color: #817878;
  text-align: right;
}

.bid-container {
  padding: 20px;
  background-color: #f8f7f4;
  border-radius: 5px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 15px;
}

.bid-price {
  h3,
  p {
    display: inline;
  }

  h3,
  h2 {
    text-align: right;
    margin-left: 10px;
  }

  .bid {
    text-align: right;
    font-weight: bold;
  }

  .buy-price {
    color: $vmeGreen;
  }

  .price {
    color: $vmeOrange;
  }

  .vat {
    text-align: left;
    margin-bottom: 0 !important;
    font-size: 0.9rem;
    background-color: #f8f7f4;
  }
}

@media only screen and (max-width: $screen-xxs) {
  .price h3 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: $screen-lg) {
  .buy-now {
    text-align: right;
    margin-bottom: 15px;
  }

  .bid-container {
    padding: 14px;
  }

  .bid-input {
    margin-bottom: 10px;
  }
}

.assist40 {
  img {
    width: 80px;
  }
}

.features-icon {
  float: right;
}

// Bid Component
.bid-info-high,
.bid-info-outbid {
  width: 100%;

  .alert-success {
    color: #ffffff !important;
    background-color: #2cac49 !important;
    border-color: #28a7458c !important;
  }

  .alert-success {
    color: #ffffff !important;
    background-color: #2cac49 !important;
    border-color: #28a7458c !important;
  }

  .alert {
    padding: 0.4rem 0 !important;
  }
}

.bid-container {
  .bid-info-high,
  .bid-info-outbid {
    display: none;
  }
}

.highest-bidder {
  .bid-info-high {
    display: block !important;
  }
}

.outbid {
  .bid-info-outbid {
    display: block !important;
  }
}
// End bid component

.form-row .div .div img {
  position: absolute;
}

.vme-back-link {
  color: #817878;

  &:hover {
    color: $vmeGreen !important;
  }
}

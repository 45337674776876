.clearFacet{
    cursor: pointer;
    color: red;
}

.label{
    cursor: default;
}
.facet-select{
    width: 100%;
}
@import '../../Variables.scss';

.register {
    .invoice{
        .invoice-fill{
            text-decoration: underline;
            cursor: pointer;
            color: $vmeGreen;
        }
    }
    
    .submit{
        p, button {
            display: inline;
        }
    }
}

.errorBorder{
    border: solid 1px $vmeRed;;
}

.errorMessage{
    color: $vmeRed;
    border-color: $vmeRed;
    margin-left:2px;
    font-size: 12px;

    
}

@media only screen and (min-width: $screen-lg){
    .add-top {
        margin-top: 35px;
        margin-left:5px;
    }
}

@media only screen and (max-width: $screen-lg){
    .add-top {
        margin-bottom: 25px;
        margin-top: 15px;
    }
}

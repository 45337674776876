body {
  height: 100%;
  margin: 0;
  padding-top: 69px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

p {
  margin-bottom: 0.5rem;
}

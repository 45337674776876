@import '../../../../Variables.scss';




@media only screen and (min-width: $screen-md){
    .search-filters {
        border-radius: 8px;
        color: #fff!important;
        padding: 1.5rem 2.5rem!important;
        margin-bottom: 20px;
        z-index:1!important;
        z-index: 998 !important;
        p {
            color:#fff;
            margin-top: 15px;
            margin-bottom: 0!important;
            font-size: 1rem!important;
        }

        &--blue {
            background: linear-gradient(75deg, #042156, #1d4289);
        }

        &--green {
            background: linear-gradient(140deg, #176128, #28a745);
        }

        select {
            background-color: white;

            option {
                background-color: white;
            }
        }

        input {
            position: relative;
            border-top-right-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
        }

        .clear {
            position: absolute;
            right: 7px;
            top: 9px;
            color: $vmeRed;
            font-size: 1.25rem;
            cursor: pointer;
        }
    }
}

@media only screen and (max-width: $screen-md -1){
    .mobile-filters {
        z-index: 10!important;
        padding-left: 0!important;
        padding-right: 0!important;

        position: -webkit-sticky!important;
        position: sticky!important;
        top: 136px;
        
        .mob-filters {
            height: calc(100vh - 150px);
            padding: 20px;
            background-color: $vmeLightGrey;
            z-index: 999;

            p{
                font-weight: 500;
            }

            select {
                background-color: #f6f6f6;

                option {
                    background-color: #e8e8e8;
                }
            }
            
            input {
                    border: 0;
                    border-bottom: 1px solid grey;
                    background-color: #f6f6f6;
                    border-radius: 0;
                    position: relative;
            }

            .clear {
                position: absolute;
                right: 0;
                top: 8px;
                color: $vmeRed;
                font-size: 1.25rem;
                cursor: pointer;
            }

            .form-control:focus { 
                border-color: $vmeGreen;
            }
        }

        .facet-select {
            background-color: #f6f6f6;
            border: 0;
            border-bottom: 1px solid grey;
            border-radius: 0;
        }
    }
}
@import "../../../Variables.scss";

.sell {
  .head {
    color: $vmeGreen !important;
    padding-left: 50px;
    font-weight: 600;
  }

  ul {
    list-style: none;
    padding: 0 0 0 24px;
  }

  ul li {
    padding-left: 3em;
    text-indent: -55px;

    p {
      margin-left: 45px;
    }
  }

  ul > li[data-text]:before {
    content: attr(data-text);
    float: left;
    margin-right: 25px;
    width: 75px;
    color: $vmeGreen;
    font-weight: 600;
  }
}

.points {
  margin: 30px;
  p {
    //font-weight: 600;
    //color: gray;
  }

  .tick {
    color: $vmeGreen;
    font-weight: 800;
  }
}

.steps {
  position: relative;
}
.steps:before {
  top: 105px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 2px;
  background-color: $vmeGreen;
  left: 50%;
  margin-left: -1px;
}

.timeline {
  margin: 0px auto;
  overflow: hidden;
  position: relative;
  padding: 0px;
  list-style-type: none;
}
.timeline .timeline-box {
  position: relative;
  float: left;
  clear: left;
  width: 45%;
  margin: 1em 2.5%;
  list-style-type: none;
  display: block;
  padding: 25px;
  background: #f8f8f8;
  z-index: 15;
  border-radius: 4px;
}

.timeline .timeline-box .timeline-title {
  color: #3c3c3c;
  font-weight: 700;
  font-size: 1em;
  float: left;
  padding-left: 25px;
  padding-top: 8px;
}
.timeline .timeline-box .timeline-details {
  clear: both;
  padding-top: 5px;
}
.timeline .timeline-box span {
  position: absolute;
  top: -15px;
  right: 24px;
  font-size: 8em;
  font-weight: 700;
  color: $vmeGreen;
  opacity: 0.1;
  z-index: -1;
}
@media all and (max-width: 600px) {
  .timeline .timeline-box {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
.timeline .timeline-box:hover:after {
  background: $vmeGreen;
}
@media all and (min-width: 650px) {
  .timeline .timeline-box:after {
    display: block;
    content: " ";
    height: 9px;
    width: 9px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    right: -6.55%;
    top: 1.5em;
    border: 2px solid $vmeGreen;
  }
}
.timeline .timeline-box:first-child {
  margin-bottom: 2.5em;
}
.timeline .timeline-box:nth-of-type(1n):before {
  position: absolute;
  top: 15px;
  right: -10px;
  display: inline-block;
  border-top: 10px solid transparent;
  border-left: 10px solid #f8f8f8;
  border-right: 0 solid #f8f8f8;
  border-bottom: 10px solid transparent;
  content: " ";
}
.timeline .timeline-box:nth-of-type(2n) {
  float: right;
  clear: right;
}
.timeline .timeline-box:nth-of-type(2n):before {
  right: auto;
  left: -10px;
  position: absolute;
  top: 15px;
  display: inline-block;
  border-top: 10px solid transparent;
  border-right: 10px solid #f8f8f8;
  border-left: 0 solid #f8f8f8;
  border-bottom: 10px solid transparent;
  content: " ";
}
@media all and (min-width: 600px) {
  .timeline .timeline-box:nth-of-type(2n) {
    margin-top: 3em;
  }
}
.timeline .timeline-box:nth-child(2n):after {
  left: -6.5%;
  right: auto;
}

.timeline-box:hover,
.timeline-box:focus {
  transform: translate(0, -5px);
  -webkit-transform: translate(0, -5px);
  -ms-transform: translate(0, -5px);
}

.timeline-box:hover,
.timeline-box:focus {
  -webkit-box-shadow: 0px 3px 0px 0px $vmeGreen;
  -moz-box-shadow: 0px 3px 0px 0px $vmeGreen;
  box-shadow: 0px 3px 0px 0px $vmeGreen;
}

.timeline-box {
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
}

.app-features {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $vmeGreen;
  }
  a,
  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
    outline: none;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .heading {
    margin-top: 0px;
  }
  .separator {
    position: relative;
    display: inline-block;
    text-transform: capitalize;
    margin-bottom: 30px;
  }
  .separator:after,
  .separator:before {
    position: absolute;
    content: "";
    width: 50px;
    height: 2px;
    background: $vmeGreen;
    top: 50%;
  }

  .separator i {
    color: $vmeGreen;
  }

  .separator:after {
    right: 140%;
  }

  .separator:before {
    left: 140%;
  }

  .amazing_feature {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .single_feature {
    border-radius: 4px;
    background: #fff none repeat scroll 0 0;
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    padding: 40px 40px;
    text-align: center;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .single_feature:before {
    border-radius: 4px;
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $vmeGreen;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .single_feature:hover,
  .single_feature:focus,
  .single_feature:active {
    color: white;
  }
  .single_feature:hover:before,
  .single_feature:focus:before,
  .single_feature:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  .feature_icon {
    display: inline-block;
  }

  .single_feature .feature_icon {
    border: 1px solid #e8e8e9;
    border-radius: 50%;
    color: #333;
    font-size: 12px;
    height: 60px;
    line-height: 68px;
    position: relative;
    text-align: center;
    transition: all 0.3s ease 0s;
    width: 60px;
    z-index: 3;
    margin-bottom: 15px;

    svg {
      font-size: 25px;
    }
  }
  .single_feature:hover .feature_icon {
    background: #fff;
    border: 1px solid #fff;
    color: $vmeGreen;
  }
  .single_feature h3 {
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 400;
    margin-top: 0px;
    overflow: hidden;
  }
  .single_feature:hover h3 {
    color: #fff;
  }
  .single_feature span {
    border-bottom: 1px dashed #ccc;
    display: block;
    margin: 15px auto 10px;
    width: 80px;
  }
  .single_feature p {
    margin-bottom: 0;
    overflow: hidden;
  }
}


.register-card {
  //background-color: #00b25a!important;
  border-radius: 1rem!important; 
  border: none!important;
  background: rgb(0, 178, 90)!important;
    background: linear-gradient(168deg, rgba(0, 178, 90, 1) 29%, rgba(2, 195, 100, 1) 88%, rgba(0, 207, 104, 1) 100%)!important;

  h5, p {
    color: white;
  }

  .btn {
    border-radius: 2rem;
    background-color: white;
    &:hover {
      background-color: #f0f0f0!important;
    }
    a {
      color: black!important;
      &:hover {
        color: black!important;
      }
    }
  }
}
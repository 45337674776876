@import '../../Variables.scss';

.vm-menu-icon {
    font-size: 1.6rem;
    color: black;
}

.vm-notification-icon{
    color: black;
    font-size: 1.3rem;

    &:focus{
        outline: none;
    }
}

.navbar-toggler:focus{
    outline:none;
}

.navbar {
    z-index: 999!important;
    min-height:69px;
}

.logo{
    max-width: 170px;
}

.logo-mob{
    max-width: 55px;
}

.vm-nav {
    padding: 0.25rem 1rem 0 1rem!important;

    -webkit-box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.15) !important;

    .nav-link{
        padding:8px 11px!important;
    }
}

// Top navigation
 .dropdown-item {
    &.active, &:hover {
        background-color: $vmeLightGrey !important;
        color: $vmeGreen!important;

    }
}

.vm-navbar .nav-link.active {
    color: $vmeGreen!important;
  }

  .login-container {
      padding-left:0!important;
      padding-right:0!important;
  }

  .profile {
    position: relative;
   
    .user-profile-icon, p {
        display: inline;
        color: grey;
    }

    p {
        top:11px;
        padding-left:8px;
    }
}

.dropdown-content a {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: grey;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item.active:hover, .dropdown-content a.active:hover {background-color: #ddd!important;}
    
.dropdown-content a:hover {background-color: #ddd!important;}

@media only screen and (max-width:$screen-lg){

        
    .navbar-toggler {
        border: none!important;
    }

    .vm-navbar .nav-link:hover {
        background-color: #ddd!important;
    }

    .dropdown-content a.active:hover {background-color: #ddd!important;}
    .dropdown-content a:hover {background-color: #ddd; color:black}

    .msg-dropdown {
            left: -150px!important;
    }

    .dropdown-content {
        display:none;
        //background-color: #f6f6f6;
    }

    .nav-item {
        position: relative;
    }

    #about-toggle{
        display:none;
        background-color: #f6f6f6;

    }

    .toggle-icon{
        position: absolute;
        top: 9px;
        right: 10px;
    }

    .account-btns{

        display: flex;

        ul {
            flex: 1;
        }
    }
    
    .logout-text {
        font-size: 0.8rem;
    }
    
    .mob-nav{
        margin-bottom: 10px;
    }

    .logout-icon{
        cursor: pointer;
    }


    .notifications-mobile {
        overflow-y: auto!important;
        max-height: 60vh!important;
    }
}

@media only screen and (min-width: $screen-lg){

    .notifications-mobile {
        display:none!important;
    }

    .nav-link.active{
        background-color: #fff!important;
    }

    .dropdown:hover .dropdown-menu {
        display: block;
        margin-top: 0; // remove the gap so it doesn't close
     }
    
    .navbar-nav {
         height: 62px;
         line-height: 45px;
    }

    .profile-nav, .msg-nav {
        float: right;
    }


    .user-profile-icon {
        font-size:1.2rem;
    }

    .vm-navbar > li {
        padding-right: 4px;
        padding-left: 4px;

    }

    /* Dropdown Button */
    .dropbtn {
        position: relative;
        border: none;
    }
    
    .dropdown {
        position: relative;
        display: inline-block;
    }
    
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f8f8f8!important;
        min-width: 160px;
        z-index: 1;
        box-shadow: 0 0.2rem 0.3em rgba(0, 0, 0, 0.15) 
    }
    
    .dropdown-content a {
        color: black!important;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

    .dropdown-content {
        top: 63px!important;

    }

    .vm-navbar .nav-link:hover {
        border-bottom: 2px solid #20a64b75!important;
    }

    .vm-navbar .nav-link.active {
        color: $vmeGreen!important;
        border-bottom: 2px solid $vmeGreen!important;
    }

    .dropdown:hover .dropdown-content {display: block;}
    
}
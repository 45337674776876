.carousel-box{
    background-color: #f6f6f6;
    // margin-bottom:50px;
    padding: 20px;
}

.desktop-only {
    display: none;
}

@media screen and (min-width: 768px) {
    .desktop-only {
        display: block;
    }
}

.mobile-only {
    display: block;
}

@media screen and (min-width: 768px) {
    .mobile-only {
        display: none;
    }
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    opacity: 1!important;
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #20a64b!important;
}
.carousel .control-next.control-arrow:before {
    border-left: 8px solid #20a64b!important;
}

.carousel.carousel-slider .control-arrow:hover {
    background: lightgrey!important;
}

.carousel .control-dots .dot {
    box-shadow: unset!important;
    background: #20a64b!important;
}
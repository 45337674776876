@import '../../../../Variables.scss';

.lot{
    width:100%;
    
    .card{
        border-radius: 0.5rem;
    }

    .head {
        p {
            font-size: 0.9rem;
        }

        h5 {
            font-size: 1.22rem;
            margin-bottom: 5px;
        }
    }

    @media only screen and (min-width: $screen-md) {

        .lot-card-body {
            min-height:185px;
        }

        .card-footer {
            text-align: right;
        }
    }

    .img-container{
        position:relative;
        display:inline-block;
        cursor: pointer;

        .overlay{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background: #8c8e8e9e;
            opacity:0;
            transition:opacity 500ms ease-in-out;

            h4 {
                color:#fff;
            }
        }
    }

    .img-label {
        &--top {
            display: inline-block;
            padding: 10px;
            width: 100%;
            line-height: 31px;
            position: absolute;
            top: 0;
            z-index: 1;
        }
    
        &--bottom {
            display: inline-block;
            padding: 5px 7px;
            width: 100%;
            line-height: 31px;
            position: absolute;
            bottom: -6px;
            z-index: 1;
        }
    }

    .img-container:hover .overlay{
        opacity:1;
    }
      
    .overlay span{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    color:#fff;
    }

    .lot-icons {
        margin-right: 4px;
        font-size: 0.9rem!important;
        color: $vmeGreen;
    }

    .location-icon {
        margin: 0 7px 0 2px;
    }
} 

.vendor-logo img {
    width:130px;
}

.buyers-fee {
    padding-top:5px;
}

.bid-icon {
    font-size: 3rem;
    color: $vmeGreen;
}


@media only screen and (max-width: $screen-lg) {
    .vendor-logo img {
        width:200px!important;
    }
}

@media only screen and (max-width: $screen-md) {
    .buyers-fee {
        padding-left:9px;
    
    }

    .buyers-fee-icon {
        margin: 0 7px 0 2px;
    }

    .vendor-logo img {
        width:150px
    }

    .vendor-logo img {
        width:150px!important;
    }
    
}

.vehicle-row{
    position: absolute; 
    bottom: 0;
}

.lot-img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.star-icon {
    color: #00B258;
    font-size: 1.25rem;
    cursor: pointer;
}

.lot-notification, .extended-bidding {
    .alert {
        padding: 0.25rem 1.25rem;
        border-radius: 0rem!important;
        margin-bottom: 0;
        font-size:0.9rem;
        border-left: 0!important;
        border-right: 0!important;
        margin-right: 0;
    }    

    .alert-success {
        background-color: #02a753;
        border-color: #02a753;
        color:white;
    }

    .alert-warning {
        color: $vmeRed;
    }

    .alert-danger {
        color: $vmeRed;
    }
}

.extended-bidding{
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    font-size:0.9rem;
}

.badge {
        padding: 0.4em 0.6em !important;
        font-size: 100%!important;
        margin: 10px 10px;
}

.badge-warning{
    background-color: #ffb123!important;
}

.cta-container {
    padding: 7px 10px!important;

    .bid-title {
        font-weight:bold;
    }
}

@media only screen and (max-width: $screen-md) {
    .cta-container {
        padding-top: 20px!important;
    }

    .badge {
        font-size: 90%!important;
    }

    .card-footer{
        text-align: center;
    }

}

.bid-price, .buynow-price {
    h4, p {
        display: inline;
    }
    
    h4{
        font-size: 2.4rem;
    }

    .start-price{
        color: $vmeBlue!important;
    }
       

    .vat-price {
        font-size: 0.8rem;
    }
}

.buynow-price {
    h4{
        color: $vmeBlue;
    }
}

@media only screen and (min-width: $screen-md) {
 
    .buyer-pad {
       padding-left: 0!important;
    }
}

@media only screen and (max-width: $screen-md) {
 
    .buyer-pad {
        padding-left: 0.25rem!important;
     }
}

@media only screen and (max-width: $screen-sm) {
    .bid-price, .buynow-price {
        h4 {
            font-size: 1.8rem;
        }
        .vat-price {
            font-size: 0.6rem;
        }
    }

    .vendor-logo img {
        width:132px!important;
    }
}

@media only screen and (max-width: $screen-xs) {
    .bid-price, .buynow-price {
        h4 {
            font-size: 1.5rem;
        }
    }
}

.view-now{
    margin-top:4rem;
}

.view-btn-mob {
    padding: 0 10px!important;
}

.ended {
    padding: 0 12px 8px 0!important;
    font-size: 1.3rem;

    .sold-lot{
        color: $vmeRed!important;
    }
    
    .closed-lot {
        color: grey!important;
    }
}


.bidder-bought {
    .card{
        border: 0.25rem solid $vmeBlue!important;
    }

    .bid-price{
        h4 {
            color: $vmeGreen!important;
        }
    }
}

.highest-bidder {
    .card {
        border: 0.25rem solid $vmeGreen!important;
    }

    .bid-price{
        h4 {
            color: $vmeGreen!important;
        }
    }
}

.bidder-outbid {
    .card{
        border: 0.25rem solid $vmeRed!important;
    }

    .bid-price{
        h4 {
            color: $vmeRed!important;
        }
    }
}

.assist40 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 10;

    img {
        width:80px;
    }

  }